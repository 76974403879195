import { ApolloClient, InMemoryCache } from '@apollo/client'

export const client = new ApolloClient({
  uri: process.env.REACT_APP_GRAPH_ENDPOINT,
  cache: new InMemoryCache(),
  defaultOptions: { query: { fetchPolicy: 'no-cache' } },
})

export const Query = client.query
export const Mutate = client.mutate
