import { configureStore } from '@reduxjs/toolkit'
import { createEpicMiddleware } from 'redux-observable'
import { reducers } from 'startup/reducers'
import { epics } from 'startup/epics'

export const store = () => {
  const epicMiddleware = createEpicMiddleware()
  const store = configureStore({
    reducer: reducers,
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(epicMiddleware),
  })
  epicMiddleware.run(epics)

  return store
}
