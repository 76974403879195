import { ofType } from 'redux-observable'
import { mergeMap, from, map, of, catchError } from 'rxjs'
import { virtues } from 'person/add-scoring/submit-form/virtues/slices/virtues'
import { Query } from 'startup/client'
import { GET_VIRTUES } from 'person/add-scoring/submit-form/virtues/get-virtues'

export const GetVirtues = action$ => action$.pipe(ofType(virtues.actions.attempt))
  .pipe(mergeMap(() => from(Query({ query: GET_VIRTUES }))))
  .pipe(map(result => result.data.virtues))
  .pipe(map(results => virtues.actions.success(results)))
  .pipe(catchError(error => of(virtues.actions.failure(error))))
