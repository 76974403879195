import { virtues } from 'person/add-scoring/submit-form/virtues/slices/virtues'
import { persons } from 'dashboard/persons/online-persons/slices/persons'
import { completed } from 'dashboard/completed/slices/completed'
import { average } from 'person/average/slices/average'
import { user } from 'dashboard/user/slices/user'
import { createScore } from 'person/add-scoring/score/slices/create-score'
import { combineReducers } from '@reduxjs/toolkit'
import { setComplete } from 'person/complete/slices/complete'
import { getProgress } from 'person/progress/slices/progress'
import { updateScore } from 'person/add-scoring/score/slices/update-score'
import { createScores } from 'person/add-scoring/score/slices/create-scores'
import { updateScores } from 'person/add-scoring/score/slices/update-scores'
import { unreadyPersons } from 'dashboard/completed/slices/unready-persons'

export const reducers = combineReducers({
  getVirtues: virtues.reducer,
  getPersons: persons.reducer,
  getCompleted: completed.reducer,
  getAverage: average.reducer,
  user: user.reducer,
  createScore: createScore.reducer,
  createScores: createScores.reducer,
  updateScore: updateScore.reducer,
  updateScores: updateScores.reducer,
  setComplete: setComplete.reducer,
  getProgress: getProgress.reducer,
  unreadyPersons: unreadyPersons.reducer,
})
