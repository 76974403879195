import { Radar } from 'react-chartjs-2'
import * as chartOptions from 'chart-section/chart/chart-options.json'
import React, { useRef } from 'react'
import { useChartAverage } from 'person/average/hooks/use-chart-average'
import { Loading } from 'chart-section/loading'
import 'chart-section/style.css'
import { saveAs } from 'file-saver'
import 'chart-section/chart/style.scss'

export const ChartGraph = () => {
  const chartAverages = useChartAverage()
  const chartRef = useRef(null)

  if (!chartAverages) {
    return <Loading />
  }

  const exportChart = () => {
    chartRef.current.resize(1000, 700)
    saveAs(chartRef.current.toBase64Image('image/png'), 'chart.png')
    chartRef.current.resize()
  }

  const data = {
    labels: chartAverages.virtuesNames,
    datasets: [
      {
        label: 'Your scores',
        data: chartAverages.personalScores,
        backgroundColor: 'rgba(120, 255, 132, 0.5)',
        borderColor: 'rgb(120, 255, 132)',
      },
      {
        label: 'Others scores',
        data: chartAverages.othersScores,
        backgroundColor: 'rgba(54, 162, 235, 0.5)',
        borderColor: 'rgb(54, 162, 235)',
      },
    ],
  }

  return <div className={'chart-div justify-content-center'}>
    <Radar className={'d-flex'} data={data} options={chartOptions} ref={chartRef} />
    <div className={'d-flex justify-content-center pb-1'}>
      <button className={'btn btn-light d-flex'} onClick={exportChart}>Export</button>
    </div>
  </div>
}
