import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSetUser } from 'dashboard/user/hooks/use-set-user'
import { usePersons } from 'dashboard/persons/online-persons/hooks/use-persons'

export const useUserLogin = () => {
  const persons = usePersons()
  const navigate = useNavigate()
  const setUser = useSetUser()

  return useCallback((values, actions) => {
    const user = persons.find(person => person.id === parseInt(values.selectPerson))
    setUser(user)
    navigate('/dashboard')
  }, [persons, setUser, navigate])
}
