import { ofType } from 'redux-observable'
import { mergeMap, from, map, of, catchError, delay } from 'rxjs'
import { Query } from 'startup/client'
import { GET_COMPLETED } from 'dashboard/completed/get-completed'
import { completed } from 'dashboard/completed/slices/completed'

export const GetCompleted = action$ => action$.pipe(ofType(completed.actions.attempt))
  .pipe(mergeMap(() => from(Query({ query: GET_COMPLETED }))))
  .pipe(map(result => result.data.completed))
  .pipe(delay(2000))
  .pipe(mergeMap(result => {
    if (!result) {
      return [completed.actions.success(result), completed.actions.attempt({})]
    }

    return [completed.actions.success(result)]
  }))
  .pipe(catchError(error => of(completed.actions.failure(error))))

/*
.pipe(delay(2000))
  .pipe(mergeMap(results => {
      console.log(results)
      if(!results.length) {
          return [unreadyPersons.actions.success(results), completed.actions.attempt({})]
      }

      return [unreadyPersons.actions.attempt({})]
  }))
 */
