import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { completed } from 'dashboard/completed/slices/completed'

export const useFetchCompleted = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(completed.actions.attempt({}))
  }, [dispatch])
}
