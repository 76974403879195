import { useAverage } from 'person/average/hooks/use-average'

const extractAverage = averages => ({
  virtuesNames: averages.map(average => average.virtue.name),
  personalScores: averages.map(average => average.personalScore),
  othersScores: averages.map(average => average.othersScore),
})

export const useChartAverage = () => {
  const average = useAverage()

  return average ? extractAverage(average) : null
}
