import { gql } from '@apollo/client'

export const CREATE_SCORES = gql`
mutation($scores: [VirtueScore]! $authorId: Int!, $targetId: Int!) {
  createScores(scores: $scores, author_id: $authorId, target_id: $targetId) {
    value
    virtue {
      id
      name
      description
    }
    author {
      id
      firstName
      lastName
    }
    target {
      id
      firstName
      lastName
    }
  }
}
`
