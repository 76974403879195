import { gql } from '@apollo/client'

export const UPDATE_SCORES = gql`
mutation($scores: [VirtueScore]!,$authorId: Int!, $targetId: Int!) {
  updateScores(scores: $scores, author_id: $authorId, target_id: $targetId) {
    value
    virtue {
      description
      name
      id
    }
    author {
      lastName
      firstName
      id
    }
    target {
      lastName
      firstName
      id
    }
  }
}
`
