import { ofType } from 'redux-observable'
import { mergeMap, from, map, of, catchError } from 'rxjs'
import { Mutate } from 'startup/client'
import { SET_COMPLETE } from 'person/complete/set-complete'
import { setComplete } from 'person/complete/slices/complete'

export const SetComplete = action$ => action$.pipe(ofType(setComplete.actions.attempt))
  .pipe(mergeMap(({ payload }) => from(Mutate({ mutation: SET_COMPLETE, variables: payload }))))
  .pipe(map(results => setComplete.actions.success(results)))
  .pipe(catchError(error => of(setComplete.actions.failure(error))))
