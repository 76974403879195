import React from 'react'
import { OnlinePersons } from 'dashboard/persons/online-persons'
import { useSetComplete } from 'person/complete/hooks/use-set-complete'
import { useNavigate } from 'react-router-dom'
import { FetchProgressBehaviour } from 'person/progress'
import { useVirtues } from 'person/add-scoring/submit-form/virtues/hooks/use-virtues'
import { useProgress } from 'person/progress/hooks/use-progress'
import { usePersons } from 'dashboard/persons/online-persons/hooks/use-persons'
import { useCheckUserLogged } from 'login/check-user-logged'
import 'dashboard/style.scss'

export const Dashboard = ({}) => {
  useCheckUserLogged()

  const navigate = useNavigate()
  const setComplete = useSetComplete()
  const persons = usePersons()
  const virtues = useVirtues()
  const { progress } = useProgress()

  const complete = () => {
    setComplete()
    navigate('/chart')
  }

  const areAllRated = () => progress.length === virtues.length * persons.length

  return <div className={'d-flex dashboard-div'}>
    <FetchProgressBehaviour />
    <div className={'container'}>
      <div className={'row online-persons'}>
        <OnlinePersons />
      </div>
      <div className={'row justify-content-center align-items-center'}>
        <button className={'d-flex btn btn-light mt-3 align-self-center justify-content-center w-50'} onClick={complete} disabled={!areAllRated()}>Complete</button>
      </div>
    </div>
  </div>
}
