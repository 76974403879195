import { createSlice } from '@reduxjs/toolkit'

export const average = createSlice({
  name: 'getAverage',
  initialState: {
    data: [{
      virtue: {
        id: null,
        name: '...',
        description: '...',
      },
      personalScore: null,
      othersScore: null,
    }],
  },
  reducers: {
    success: (state, action) => ({ ...state, data: action.payload }),
    failure: (state, action) => ({ ...state, errors: action.payload }),
    attempt: (state, action) => ({ ...state, input: action.payload }),
  },
})
