import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { useUser } from 'dashboard/user/hooks/use-user'
import { setComplete } from 'person/complete/slices/complete'

export const useSetComplete = () => {
  const dispatch = useDispatch()
  const user = useUser()

  return useCallback(() => {
    if (user.id) {
      dispatch(setComplete.actions.attempt({ personId: user.id }))
    }
  }, [dispatch, user])
}
