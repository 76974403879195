import { createSlice } from '@reduxjs/toolkit'

export const virtues = createSlice({
  name: 'getVirtues',
  initialState: {
    data: [{
      id: '...',
      name: '...',
      description: '...',
    }],
  },
  reducers: {
    success: (state, action) => ({ ...state, data: action.payload }),
    failure: (state, action) => ({ ...state, errors: action.payload }),
    attempt: (state, action) => ({ ...state, input: action.payload }),
  },
})
