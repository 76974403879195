import { TopNavigation } from 'components/layout/top-navigation'
import 'components/layout/style.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { useFetchVirtues } from 'person/add-scoring/submit-form/virtues/hooks/use-fetch-virtues'

export const Layout = ({ children }) => {
  useFetchVirtues()

  return <div className={'layout-div'}>
    <TopNavigation />
    <div className={'background-image'}>
      {children}
    </div>
  </div>
}

Layout.propTypes = { children: PropTypes.object.isRequired }
