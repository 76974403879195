import { gql } from '@apollo/client'

export const GET_AVERAGE = gql`
  query($personId: Int!) {
    average(personId: $personId) {
      virtue {
        id
        name
        description
      }
      personalScore
      othersScore
    }
  }
`
