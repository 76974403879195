import { ofType } from 'redux-observable'
import { mergeMap, from, map, of, catchError, delay } from 'rxjs'
import { Query } from 'startup/client'
import { GET_UNREADY_PERSONS } from 'dashboard/completed/get-unready-persons'
import { unreadyPersons } from 'dashboard/completed/slices/unready-persons'
import { completed } from 'dashboard/completed/slices/completed'

export const GetUnreadyPersons = action$ => action$.pipe(ofType(unreadyPersons.actions.attempt))
  .pipe(mergeMap(() => from(Query({ query: GET_UNREADY_PERSONS }))))
  .pipe(map(result => result.data.unreadyPersons))
  .pipe(delay(2000))
  .pipe(mergeMap(results => {
    if (results.length) {
      return [unreadyPersons.actions.success(results), unreadyPersons.actions.attempt(results)]
    }

    return [completed.actions.success(results)]
  }))
  .pipe(catchError(error => of(unreadyPersons.actions.failure(error))))
