import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { useUser } from 'dashboard/user/hooks/use-user'
import { getProgress } from 'person/progress/slices/progress'

export const useFetchProgress = () => {
  const dispatch = useDispatch()
  const user = useUser()

  useEffect(() => {
    if (user.id) {
      dispatch(getProgress.actions.attempt({ personId: user.id }))
    }
  }, [dispatch, user])
}
