import React from 'react'
import { UnreadyPeople } from 'chart-section/incomplete/unready-people'
import { IncompleteBehaviour } from 'chart-section/incomplete/incomplete-behaviour'
import 'chart-section/incomplete/style.scss'

export const Incomplete = () => <div className={'d-flex flex-column incomplete-div justify-content-center align-self-center'}>
  <IncompleteBehaviour />
  <h1 className={'d-flex justify-content-center align-items-center'}>Not everyone finished.</h1>
  <h1 className={'d-flex justify-content-center pb-4'}>Waiting for:</h1>
  <UnreadyPeople />
</div>
