import 'person/style.scss'
import { useLocation } from 'react-router-dom'
import { Profile } from 'person/profile'
import { AddScoring } from 'person/add-scoring'
import React from 'react'
import { useCheckUserLogged } from 'login/check-user-logged'

export const Person = () => {
  useCheckUserLogged()

  const { state } = useLocation()
  const { person } = state

  return <div className={'person-page d-flex h-75'}>
    <div className={'container'}>
      <div className={'profile col mt-n5 justify-content-center align-items-center text-md-center'}>
        <Profile person={person} />
      </div>
      <div className={'col'}>
        <AddScoring />
      </div>
    </div>
  </div>
}
