import { useField } from 'formik'
import React, { useState } from 'react'
import 'person/add-scoring/submit-form/range-input/style.scss'

export const RangeInput = ({ name, label, max, min, step, initialValue, description }) => {
  const [{ onChange }] = useField(name)
  const [value, setValue] = useState(initialValue)

  const changeValue = event => {
    setValue(event.target.value)
    onChange(event)
  }

  return <div className={'input-group'}>
    <div className={'container'}>
      <div className={'row'}>
        <label className={'col'}>
          {label}
        </label>
        <label className={'col text-end'}>
          {value}
        </label>
        <p>{description}</p>
      </div>
    </div>
    <input className={'input-styling'} type={'range'} max={max} min={min} step={step} name={name} value={value} onChange={changeValue} />
  </div>
}
