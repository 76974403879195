import React from 'react'
import PropTypes from 'prop-types'

export const Name = ({ firstName, lastName }) => <h1>
  {firstName}
  {' '}
  {lastName}
</h1>

Name.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
}
