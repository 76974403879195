import 'dashboard/persons/person-list-item/style.scss'
import { Link } from 'react-router-dom'
import React from 'react'
import { useProgress } from 'person/progress/hooks/use-progress'
import { useVirtues } from 'person/add-scoring/submit-form/virtues/hooks/use-virtues'

export const PersonListItem = ({ person }) => {
  const { progress } = useProgress()
  const virtues = useVirtues()
  const personProgress = progress.filter(scoring => scoring.targetId === person.id)

  return <Link className={'link-person'} to={`/person/${person.id}`} state={{ person }}>
    <li className={'list-group-item d-flex justify-content-between align-items-center'}>
      <span>{`${person.firstName}  ${person.lastName}`}</span>
      { personProgress.length === virtues.length ? <span className={'done-text'}>Done</span> : <span className={'progress-text'}>{`${personProgress.length}/${virtues.length}`}</span>}
    </li>
  </Link>
}
