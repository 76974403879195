import { createSlice } from '@reduxjs/toolkit'

export const persons = createSlice({
  name: 'getPersons',
  initialState: {
    data: [{
      id: '...',
      firstName: '...',
      lastName: '...',
    }],
  },
  reducers: {
    success: (state, action) => ({ ...state, data: action.payload }),
    failure: (state, action) => ({ ...state, errors: action.payload }),
    attempt: (state, action) => ({ ...state, input: action.payload }),
  },
})
