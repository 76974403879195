import React from 'react'
import { Layout } from 'components/layout'
import { ApolloProvider } from '@apollo/client'
import { client } from 'startup/client'
import { Provider } from 'react-redux'
import { store } from 'startup/store'
import { Routing } from 'startup/routing'

import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
} from 'chart.js'

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend)

export const Startup = () => <ApolloProvider client={client}>
  <Provider store={store()}>
    <Layout>
      <Routing />
    </Layout>
  </Provider>
</ApolloProvider>
