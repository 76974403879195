import React from 'react'
import { Formik, FieldArray } from 'formik'
import { RangeInput } from 'person/add-scoring/submit-form/range-input'
import 'person/add-scoring/submit-form/style.scss'
import { useApplyScoring } from 'person/add-scoring/submit-form/hooks/use-apply-scoring'
import { useProgress } from 'person/progress/hooks/use-progress'
import { useParams } from 'react-router-dom'
import { useProgressedVirtues } from 'person/add-scoring/submit-form/hooks/use-progressed-virtues'
import { useGoBack } from 'person/add-scoring/submit-form/hooks/use-go-back'
import { schema } from 'person/add-scoring/submit-form/schema'
import { ErrorSubmit } from 'person/add-scoring/submit-form/error-submit'

export const SubmitForm = () => {
  const applyScore = useApplyScoring()
  const { progress } = useProgress()
  const { id } = useParams()
  const personId = parseInt(id)
  const virtues = useProgressedVirtues(personId)
  const wasSubmitted = progress.some(scoring => scoring.targetId === personId)
  const goBack = useGoBack()

  return <Formik initialValues={{ virtues }}
                 onSubmit={applyScore}
                 validationSchema={schema}
                 enableReinitialize>
    {({ isSubmitting, values, handleSubmit, errors, touched }) => (
      <form onSubmit={handleSubmit}>
        <div className={'main-scoring-div container align-items-center justify-content-center'}>
          <div className={'row'}>
            <FieldArray name={'virtues'}
                        render={_ => (
                          <div className={'justify-content-center align-items-center form-container'}>
                            {values.virtues.map((virtue, index) => <div key={index}>
                              <RangeInput max={5} min={0} step={1} name={`virtues[${index}].value`}
                                          label={virtue.name} initialValue={virtues[index].value} description={virtue.description} />
                            </div>)}
                          </div>
                        )} />
          </div>
          <div className={'row buttons'}>
            <div className={'col-4'}>
              <button className={'btn button btn-light'} type={'button'} onClick={goBack}>Back</button>
            </div>
            <div className={'col-8'}>
              <button className={'btn button btn-light'} disabled={isSubmitting}>{wasSubmitted ? 'Update' : 'Submit'}</button>
            </div>
          </div>
          { errors.virtues && touched.virtues ? <ErrorSubmit /> : <div />}
        </div>
      </form>
    )}
  </Formik>
}
