import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { useUser } from 'dashboard/user/hooks/use-user'
import { updateScores } from 'person/add-scoring/score/slices/update-scores'

export const useUpdateScores = () => {
  const dispatch = useDispatch()
  const user = useUser()

  return useCallback(({ scores, targetId }) => {
    if (user.id) {
      dispatch(updateScores.actions.attempt({ scores, authorId: user.id, targetId }))
    }
  }, [dispatch, user])
}
