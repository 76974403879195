import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { unreadyPersons } from 'dashboard/completed/slices/unready-persons'

export const useFetchUnreadyPersons = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(unreadyPersons.actions.attempt({}))
  }, [dispatch])
}
