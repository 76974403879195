import { gql } from '@apollo/client'

export const GET_UNREADY_PERSONS = gql`
  query {
    unreadyPersons {
      id
      firstName
      lastName
    }
  }
`
