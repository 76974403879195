import { createSlice } from '@reduxjs/toolkit'

export const setComplete = createSlice({
  name: 'setComplete',
  initialState: null,
  reducers: {
    success: (state, action) => ({ ...state, data: action.payload }),
    failure: (state, action) => ({ ...state, errors: action.payload }),
    attempt: (state, action) => ({ ...state, input: action.payload }),
  },
})
