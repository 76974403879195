import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { average } from 'person/average/slices/average'
import { useUser } from 'dashboard/user/hooks/use-user'

export const useFetchAverage = () => {
  const dispatch = useDispatch()
  const user = useUser()

  useEffect(() => {
    if (user.id) {
      dispatch(average.actions.attempt({ personId: user.id }))
    }
  }, [dispatch, user])
}
