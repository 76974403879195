import { ofType } from 'redux-observable'
import { mergeMap, from, map, of, catchError } from 'rxjs'
import { Query } from 'startup/client'
import { getProgress } from 'person/progress/slices/progress'
import { GET_PROGRESS } from 'person/progress/get-progress'

export const ReGetProgress = (action$, state$) => action$.pipe(ofType(getProgress.actions.reAttempt))
  .pipe(mergeMap(() => from(Query({ query: GET_PROGRESS, variables: { personId: state$.value.getProgress.userId } }))))
  .pipe(map(result => result.data.progress))
  .pipe(map(results => getProgress.actions.success(results)))
  .pipe(catchError(error => of(getProgress.actions.failure(error))))
