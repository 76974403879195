import 'chart-section/incomplete/unready-people/style.scss'
import React from 'react'
import PropTypes from 'prop-types'

export const UnreadyPerson = ({ person }) => <div className={'row'}>
  <h3 className={'d-flex justify-content-center unready-person'}>
    {person.firstName}
    {' '}
    {person.lastName}
  </h3>
</div>

UnreadyPerson.propTypes = { person: PropTypes.object.isRequired }
