import { combineEpics } from 'redux-observable'
import { GetPersons } from 'dashboard/persons/online-persons/epics/get'
import { GetCompleted } from 'dashboard/completed/epics/get'
import { GetVirtues } from 'person/add-scoring/submit-form/virtues/epics/get'
import { GetAverage } from 'person/average/epics/get'
import { CreateScore } from 'person/add-scoring/score/epics/create'
import { SetComplete } from 'person/complete/epics/set'
import { GetProgress } from 'person/progress/epics/get'
import { UpdateScore } from 'person/add-scoring/score/epics/update'
import { CreateScores } from 'person/add-scoring/score/epics/create-batch'
import { UpdateScores } from 'person/add-scoring/score/epics/update-batch'
import { GetUnreadyPersons } from 'dashboard/completed/epics/get-unready-persons'
import { ReGetProgress } from 'person/progress/epics/re-get'

export const epics = combineEpics(GetPersons, GetCompleted, GetVirtues, GetAverage, CreateScore, CreateScores, UpdateScore, UpdateScores, SetComplete, GetProgress, ReGetProgress, GetUnreadyPersons)
