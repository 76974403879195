import { createSlice } from '@reduxjs/toolkit'

export const user = createSlice({
  name: 'user',
  initialState: {
    data: {
      id: null,
      firstName: null,
      lastName: null,
    },
  },
  reducers: { set: (state, action) => ({ ...state, data: action.payload }) },
})
