import React from 'react'
import { ChartGraph } from 'chart-section/chart/chart'
import { Name } from 'chart-section/chart/name'
import PropTypes from 'prop-types'
import 'chart-section/style.css'
import { AverageBehaviour } from 'person/average'

export const DisplayChart = ({ user }) => <div className={'container'}>
  <AverageBehaviour />
  <div className={'row align-items-start pt-3'}>
    <div className={'d-flex justify-content-center'}>
      <Name firstName={user.firstName} lastName={user.lastName} />
    </div>
  </div>
  <div className={'row justify-content-center'}>
    <ChartGraph />
  </div>
</div>

DisplayChart.propTypes = { user: PropTypes.object.isRequired }
