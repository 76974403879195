import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { virtues } from 'person/add-scoring/submit-form/virtues/slices/virtues'

export const useFetchVirtues = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(virtues.actions.attempt({}))
  }, [dispatch])
}
