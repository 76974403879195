import { Dashboard } from 'dashboard'
import { Person } from 'person'
import { Login } from 'login'
import { ChartSection } from 'chart-section'
import React from 'react'

export const routes = [{
  path: '/dashboard',
  element: <Dashboard />,
}, {
  path: '/person/:id',
  element: <Person />,
}, {
  path: '/',
  element: <Login />,
}, {
  path: '/chart',
  element: <ChartSection />,
}]
