import { useVirtues } from 'person/add-scoring/submit-form/virtues/hooks/use-virtues'
import { useProgress } from 'person/progress/hooks/use-progress'

export const useProgressedVirtues = personId => {
  const virtues = useVirtues()
  const { progress } = useProgress()
  const scopedPersonProgress = progress.filter(scoring => scoring.targetId === personId)

  return virtues.map(virtue => {
    const lastScoring = scopedPersonProgress.find(scoring => scoring.virtueId === virtue.id)
    const setValue = lastScoring ? lastScoring.score : 0

    return { ...virtue, value: setValue }
  })
}
