import { useField } from 'formik'
import React from 'react'
import 'login/select-person/style.scss'

export const SelectPerson = ({ label, ...props }) => {
  const [field, meta] = useField(props)

  return (
    <>
      <h2 className={'select-label'}>{label}</h2>

      <div className={'container'}>
        <div className={'row'}>
          <select {...field}
                  {...props}
                  className={meta.touched && meta.error ? 'input-error' : ''} />
        </div>
        <div className={'col'}>
          <div className={'error'}>{meta.error}</div>
        </div>
      </div>
    </>
  )
}
