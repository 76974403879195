import { useCreateScores } from 'person/add-scoring/score/hooks/use-create-scores'
import { useNavigate, useParams } from 'react-router-dom'
import { useCallback } from 'react'
import { useUpdateScores } from 'person/add-scoring/score/hooks/use-update-scores'
import { useProgress } from 'person/progress/hooks/use-progress'

export const useApplyScoring = () => {
  const createScores = useCreateScores()
  const updateScores = useUpdateScores()
  const { id } = useParams()
  const personId = parseInt(id)
  const navigate = useNavigate()
  const { progress } = useProgress()
  const wasSubmitted = progress.some(scoring => scoring.targetId === personId)

  return useCallback((values, actions) => {
    const newScores = {
      scores: values.virtues.map(virtue => ({ score: virtue.value, virtue_id: virtue.id })),
      targetId: personId,
    }

    if (wasSubmitted) {
      updateScores(newScores)
    } else {
      createScores(newScores)
    }

    actions.resetForm()
    navigate('/dashboard')
  }, [navigate, updateScores, createScores, personId, wasSubmitted])
}
