import React from 'react'
import { useCompleted } from 'dashboard/completed/hooks/use-completed'
import { DisplayChart } from 'chart-section/chart'
import { Incomplete } from 'chart-section/incomplete'
import { Loading } from 'chart-section/loading'
import 'chart-section/style.css'
import { useUser } from 'dashboard/user/hooks/use-user'
import { ChartBehaviour } from 'chart-section/chart-behaviour'
import { useCheckUserLogged } from 'login/check-user-logged'

export const ChartSection = () => {
  useCheckUserLogged()

  const user = useUser()
  const { completedState, completedLoading } = useCompleted()

  return <div className={'d-flex main-chart-div justify-content-center'}>
    <ChartBehaviour />
    {
      completedLoading ? <Loading /> : completedState ? <DisplayChart user={user} /> : <Incomplete />
    }
  </div>
}
