import { usePersons } from 'dashboard/persons/online-persons/hooks/use-persons'
import { PersonListItem } from 'dashboard/persons/person-list-item'
import React from 'react'
import { FetchPersonsBehaviour } from 'dashboard/persons/online-persons/fetch-persons-behaviour'

export const OnlinePersons = () => {
  const persons = usePersons()

  return <div className={'d-flex'}>
    <FetchPersonsBehaviour />
    <ul className={'list-group w-100'}>
      {persons.map(person => <PersonListItem key={person.id} person={person} />)}
    </ul>
  </div>
}
