import { useUser } from 'dashboard/user/hooks/use-user'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

export const useCheckUserLogged = () => {
  const user = useUser()
  const navigate = useNavigate()

  useEffect(() => {
    if (!user.id) { navigate('/') }
  }, [navigate, user])
}
