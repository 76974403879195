import { createSlice } from '@reduxjs/toolkit'

export const unreadyPersons = createSlice({
  name: 'unreadyPersons',
  initialState: { data: [], loading: true },
  reducers: {
    success: (state, action) => ({ ...state, data: action.payload, loading: false }),
    failure: (state, action) => ({ ...state, errors: action.payload, loading: false }),
    attempt: (state, action) => ({ ...state, input: action.payload }),
  },
})
