import { createSlice } from '@reduxjs/toolkit'

export const getProgress = createSlice({
  name: 'getProgress',
  initialState: { data: [], userId: null, loading: false },
  reducers: {
    success: (state, action) => ({ ...state, data: action.payload, loading: false }),
    failure: (state, action) => ({ ...state, errors: action.payload, loading: false }),
    attempt: (state, action) => ({ ...state, input: action.payload, userId: action.payload.personId, loading: true }),
    reAttempt: (state, action) => ({ ...state, input: action.payload, loading: true }),
  },
})
