import { ofType } from 'redux-observable'
import { mergeMap, from, map, of, catchError } from 'rxjs'
import { Mutate } from 'startup/client'
import { createScores } from 'person/add-scoring/score/slices/create-scores'
import { CREATE_SCORES } from 'person/add-scoring/score/create-scores'
import { getProgress } from 'person/progress/slices/progress'

export const CreateScores = action$ => action$.pipe(ofType(createScores.actions.attempt))
  .pipe(mergeMap(({ payload }) => from(Mutate({ mutation: CREATE_SCORES, variables: payload }))))
  .pipe(map(result => result.data.createScores))
  .pipe(mergeMap(results => [createScores.actions.success(results), getProgress.actions.reAttempt({})]))
  .pipe(catchError(error => of(createScores.actions.failure(error))))
