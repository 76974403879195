import { useUnreadyPersons } from 'dashboard/completed/hooks/use-unready-persons'
import React from 'react'
import { UnreadyPerson } from 'chart-section/incomplete/unready-people/unready-person'
import { FetchingList } from 'chart-section/incomplete/unready-people/fetching-list'
import 'chart-section/incomplete/style.scss'

export const UnreadyPeople = () => {
  const { unreadyPersons, unreadyPersonsLoading } = useUnreadyPersons()

  return <div className={'d-flex justify-content-center'}>
    <div className={'container unready-list'}>
      { unreadyPersonsLoading ? <FetchingList /> : unreadyPersons.map(person => <UnreadyPerson person={person} key={person.id} />) }
    </div>
  </div>
}
