import { ofType } from 'redux-observable'
import { mergeMap, from, map, of, catchError } from 'rxjs'
import { Query } from 'startup/client'
import { GET_AVERAGE } from 'person/average/get-average'
import { average } from 'person/average/slices/average'

export const GetAverage = action$ => action$.pipe(ofType(average.actions.attempt))
  .pipe(mergeMap(({ payload }) => from(Query({ query: GET_AVERAGE, variables: payload }))))
  .pipe(map(result => result.data.average))
  .pipe(map(results => average.actions.success(results)))
  .pipe(catchError(error => of(average.actions.failure(error))))
