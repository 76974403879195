import { gql } from '@apollo/client'

export const GET_PROGRESS = gql`
  query($personId: Int!) {
      progress(personId: $personId) {
        targetId
        virtueId
        score
      }
    }
`
