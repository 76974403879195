import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { persons } from 'dashboard/persons/online-persons/slices/persons'

export const useFetchPersons = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(persons.actions.attempt({}))
  }, [dispatch])
}
