import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { user } from 'dashboard/user/slices/user'

export const useSetUser = () => {
  const dispatch = useDispatch()

  return useCallback(payload => {
    dispatch(user.actions.set(payload))
  }, [dispatch])
}

