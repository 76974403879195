import { gql } from '@apollo/client'

export const UPDATE_SCORE = gql`
mutation($score: Int!, $virtueId: Int!, $authorId: Int!, $targetId: Int!) {
  updateScore(score: $score, virtue_id: $virtueId, author_id: $authorId, target_id: $targetId) {
    value
    virtue {
      id
      name
      description
    }
    author {
      id
      firstName
      lastName
    }
    target {
      id
      firstName
      lastName
    }
  }
}
`
