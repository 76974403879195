import { ofType } from 'redux-observable'
import { mergeMap, from, map, of, catchError } from 'rxjs'
import { persons } from 'dashboard/persons/online-persons/slices/persons'
import { Query } from 'startup/client'
import { GET_PERSONS } from 'dashboard/persons/online-persons/epics/get-persons'

export const GetPersons = action$ => action$.pipe(ofType(persons.actions.attempt))
  .pipe(mergeMap(() => from(Query({ query: GET_PERSONS }))))
  .pipe(map(result => result.data.persons))
  .pipe(map(results => persons.actions.success(results)))
  .pipe(catchError(error => of(persons.actions.failure(error))))
