import { gql } from '@apollo/client'

export const GET_VIRTUES = gql`
  query {
      virtues {
        id
        name
        description
      }
  }
`
