import React from 'react'
import { SelectPerson } from 'login/select-person'
import { Form, Formik } from 'formik'
import { usePersons } from 'dashboard/persons/online-persons/hooks/use-persons'
import 'login/style.scss'
import { FetchPersonsBehaviour } from 'dashboard/persons/online-persons/fetch-persons-behaviour'
import { useUserLogin } from 'login/hooks/use-user-login'
import { schemaPerson } from 'login/validators'

export const Login = () => {
  const persons = usePersons()
  const userLogin = useUserLogin()

  return <div>
    <FetchPersonsBehaviour />
    <Formik initialValues={{ selectPerson: '' }} onSubmit={
      userLogin
    } validationSchema={schemaPerson} enableReinitialize>
      {({ isSubmitting }) => (
        <Form className={'form-login d-flex justify-content-center align-items-center'}>
          <SelectPerson label={'Who are you?'}
                        name={'selectPerson'}
                        type={'text'}
                        placeholder={'Please select a person'}>

            <option value={''}>Please select a person</option>
            {
              persons && persons.map(person => <option value={person.id} key={person.id}>{`${person.firstName} ${person.lastName}`}</option>)
            }
          </SelectPerson>

          <button className={'btn btn-dark d-flex'} disabled={isSubmitting} type={'submit'}>Enter</button>
        </Form>
      )}
    </Formik>
  </div>
}
