import { ofType } from 'redux-observable'
import { mergeMap, from, map, of, catchError } from 'rxjs'
import { Mutate } from 'startup/client'
import { updateScore } from 'person/add-scoring/score/slices/update-score'
import { UPDATE_SCORE } from 'person/add-scoring/score/update-score'
import { getProgress } from 'person/progress/slices/progress'

export const UpdateScore = action$ => action$.pipe(ofType(updateScore.actions.attempt))
  .pipe(mergeMap(({ payload }) => from(Mutate({ mutation: UPDATE_SCORE, variables: payload }))))
  .pipe(map(result => result.data.updateScore))
  .pipe(mergeMap(results => [updateScore.actions.success(results), getProgress.actions.reAttempt({})]))
  .pipe(catchError(error => of(updateScore.actions.failure(error))))
